<template>
    <div class="app-container">
        <el-form :inline="true" :model="queryParams" :size="$store.getters.size" ref="queryParams">
            <el-form-item label="角色名称">
                <el-input type="text" v-model="queryParams.roleName" placeholder="角色名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb20">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    v-if="$app.haveRole('system:role:add')"
                    icon="el-icon-plus"
                    @click="handleAdd"
                    size="mini">新增</el-button>
                <el-button
                    type="danger"
                    plain
                    v-if="$app.haveRole('system:role:deletes')"
                    :disabled="multipleSelection.length<=0"
                    icon="el-icon-delete"
                    size="mini" @click="handleDeletes">批量删除</el-button>
            </el-col>
        </el-row>
        <el-table v-loading="loading" @selection-change="handleSelectionChange"
                  row-key="guid"  :data="tableData" :size="$store.getters.size">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="角色名称" prop="roleName"></el-table-column>
            <el-table-column label="排序值" prop="sort" width="100" align="center"></el-table-column>
            <el-table-column label="状态" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 1" effect="plain" size="small">正常</el-tag>
                    <el-tag v-else type="danger" effect="plain" size="small">禁用</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)"
                               v-if="$app.haveRole('system:role:edit')">修改</el-button>
                    <el-button type="text" icon="el-icon-delete" @click="handleRemove(scope.row)"
                               v-if="$app.haveRole('system:role:delete')">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page-item :total="total" ref="pageItem"/>
        <edit ref="edit" />
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem'
    import Edit from './edit'
    import {getList, removeRole, deletes} from '@/api/system/role'

    export default {
        name: "Role",
        components: {PageItem, Edit},
        data(){
            return {
                queryParams: {
                    roleName: undefined
                },
                loading: false,
                tableData: [],
                total: 0,
                multipleSelection: []
            }
        },
        mounted() {
            this.getTableData();
        },
        methods: {
            getTableData(){
                if(this.loading){return;}
                this.loading = true;
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                getList(this.queryParams).then(({code, data})=>{
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 查询按钮 */
            handleQuery(){
                this.$refs['pageItem'].current = 1;
                this.getTableData();
            },
            /** 添加 */
            handleAdd(){
                this.$refs.edit.handleAdd();
            },
            /** 修改 */
            handleEdit(row){
                this.$refs.edit.handleEdit(row.guid);
            },
            /** 删除 */
            handleRemove(row){
                this.$confirm('你确定要删除该角色?','提示',{
                    type: "warning"
                }).then(()=>{
                    removeRole(row.guid).then(({code})=>{
                        if(code === 0){
                            this.$message.success('删除成功');
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 批量删除 */
            handleDeletes(){
                this.$confirm('你确定要删除选中数据?','提示', {
                    type: "warning"
                }).then(()=>{
                    if(this.multipleSelection.length<=0){this.$message.error('未选择需要删除的数据')}
                    else{
                        const ids = this.multipleSelection.map(item=>item.guid)
                        deletes(ids).then(({code})=>{
                            if(code === 0){
                                this.$message.success("批量删除成功");
                                this.getTableData();
                            }
                        })
                    }
                }).catch(()=>{})
            },
            handleSelectionChange(val){
                this.multipleSelection = val;
            }
        }
    }
</script>

<style scoped>

</style>
