import request from '@/utils/request'


// 查询菜单列表
export function listMenu(params) {
    return request({
        url: 'system/menu/list',
        method: "GET",
        params: params
    })
}

// 查询菜单详细
export function getMenu(menuId) {
    return request({
        url: '/system/menu/get/' + menuId,
        method: 'get'
    })
}


// 新增菜单
export function addMenu(data) {
    return request({
        url: '/system/menu/put',
        method: 'POST',
        data: data
    })
}

// 修改菜单
export function updateMenu(data) {
    return request({
        url: '/system/menu/put',
        method: 'PUT',
        data: data
    })
}

// 删除菜单
export function delMenu(menuId) {
    return request({
        url: '/system/menu/remove/' + menuId,
        method: 'delete'
    })
}


// 所有菜单
export function getMenuAll() {
    return request({
        url: '/system/menu/listMenu',
        method: 'GET'
    })
}
