<template>
    <div class="page-item" style="text-align: right;margin-top: 14px;margin-right: 20px;">
        <el-pagination
           background
           layout="total , sizes, prev, pager, next, jumper"
           :total="total"
           :current-page="current"
           :page-size="pageSize"
           :pager-count="5"
           :page-sizes="[10, 20, 50, 100, 200]"
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "PageItem",
        props:{
            total: {
                type: Number,
                default: 0
            }
        },
        data(){
            return{
                pageSize: 10,
                current: 1
            }
        },
        methods: {
            handleSizeChange: function (val) {
                this.pageSize = val;
                this.current = 1;
                this.$parent.getTableData();
            },
            handleCurrentChange: function (val) {
                this.current = val;
                console.log(val)
                this.$parent.getTableData();
            },
            getCurrent(){
                return this.current;
            },
            getPageSize(){
                return this.pageSize;
            }
        }
    }
</script>

<style scoped>

</style>
