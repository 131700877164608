<template>
    <el-dialog :title="title" :visible.sync="open" width="580px" append-to-body :close-on-click-modal="false">
        <el-row :gutter="15">
            <el-form :size="$store.getters.size" label-width="90px" :model="form" ref="form" :rules="rules">
                <el-col :span="24">
                    <el-form-item label="角色名称" prop="roleName">
                        <el-input type="text" v-model="form.roleName" placeholder="请输入角色名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="显示排序" prop="sort">
                        <el-input-number v-model="form.sort" controls-position="right" :min="0" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="form.status">
                            <el-radio v-for="item in statusOption" :label="item.value" :value="item.value" :key="item.value">{{item.label}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="菜单分配">
                        <role-menu :menuList="menuList" ref="roleMenu"/>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark"
                                  :autosize="{ minRows: 4, maxRows: 6}"
                                  placeholder="请填写备注"></el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button :size="$store.getters.size" type="primary" @click="submitForm" :loading="loading">确 定</el-button>
            <el-button :size="$store.getters.size" @click="cancel">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {saveRole, getRole, updateRole} from '@/api/system/role'
    import {getMenuAll} from '@/api/system/menu'
    import RoleMenu from './role_menu'

    export default {
        name: "RoleEdit",
        components: {RoleMenu},
        data(){
            return{
                open: false,
                form: {},
                title: '',
                loading: false,
                statusOption: [{label:'正常', value: 1},{label:'禁用', value: 2}],
                menuList: [],
                rules: {
                    roleName: [{ required: true, message: "角色名称不能为空", trigger: "blur" }],
                }
            }
        },
        mounted() {
            this.getMenu()
        },
        methods: {
            /** 新增 */
            handleAdd(){
                this.open = true;
                this.title = '新增角色';
                this.reset();
            },
            /** 修改 */
            handleEdit(gid){
                this.open = true;
                this.title = '修改角色';
                this.reset();
                getRole(gid).then(({code, data})=>{
                    if(code === 0){
                        this.form = data;
                        this.$refs['roleMenu'].setChecked(data.menuIds);
                    }
                })
            },
            /** 提交数据 */
            submitForm(){
                this.$refs['form'].validate(valid =>{
                    if(valid){
                        this.form.menuIds = this.$refs['roleMenu'].checkMenus;
                        this.loading = true;
                        if(this.form.guid !== undefined){
                            updateRole(this.form).then(({code})=>{
                                this.loading = false;
                                if(code === 0){
                                    this.$message.success("修改成功");
                                    this.cancel();
                                    this.$parent.getTableData();
                                }
                            }).catch(()=>{this.loading = false;})
                        }else{
                            saveRole(this.form).then(({code})=>{
                                this.loading = false;
                                if(code === 0){
                                    this.$message.success("新增成功");
                                    this.cancel();
                                    this.$parent.getTableData();
                                }
                            }).catch(()=>{this.loading = false;})
                        }
                    }
                })
            },
            /** 关闭弹窗 */
            cancel(){
                this.open = false;
                this.reset();
            },
            /** 重置表单 */
            reset(){
                this.form = {
                    roleName: undefined,
                    sort: 10,
                    remark: undefined,
                    status: 1,
                    menuIds: [],
                    guid: undefined
                }
                if(this.$refs['roleMenu']){
                    this.$refs['roleMenu'].setCheckedKeys();
                }
            },
            getMenu(){
                getMenuAll().then(({code, data})=>{
                    if(code===0){
                        this.menuList = data;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
