import request from '@/utils/request'


// 分页获取角色
export function getList(data) {
    return request({
        url: '/system/role/list',
        method: 'GET',
        params: data
    })
}

// 获取所有角色
export function getRoleAll() {
    return request({
        url: '/system/role/getAll',
        method: 'GET'
    })
}

// 保存角色
export function saveRole(param) {
    return request({
        url: '/system/role/put',
        method: 'POST',
        data: param
    })
}

// 修改角色
export function updateRole(param) {
    return request({
        url: '/system/role/put',
        method: 'PUT',
        data: param
    })
}


// 删除角色
export function removeRole(gid) {
    return request({
        url: '/system/role/remove/'+gid,
        method: 'DELETE'
    })
}

// 批量删除
export function deletes(ids) {
    return request({
        url: '/system/role/removes',
        method: 'DELETE',
        data: ids
    })
}

export function getRole(gid) {
    return request({
        url: '/system/role/get/'+gid,
        method: 'GET'
    })
}
