<template>
    <div class="menu-tree-select">
        <el-tree
                :data="menuList"
                show-checkbox
                :default-expand-all="false"
                node-key="guid"
                ref="menuTree"
                expand-on-click-node
                @check="clickDeal"
                highlight-current
                :props="defaultProps">
        </el-tree>
    </div>
</template>

<script>
    export default {
        name: "RoleMenu",
        props: {
            menuList: {
                type: Array,
                default: undefined
            }
        },
        data(){
            return{
                defaultProps: {label: 'menuName', children: 'children'},
                checkMenus: [],
            }
        },
        methods: {
            clickDeal: function(currentObj, treeStatus){
                this.checkMenus = treeStatus.checkedKeys.concat(treeStatus.halfCheckedKeys);
            },
            setChecked(menuIds){
                this.checkMenus = menuIds;
                menuIds.forEach((i) => {
                    let node = this.$refs.menuTree.getNode(i);
                    if(node.isLeaf){
                        this.$refs.menuTree.setChecked(node, true);
                    }
                });
            },
            setCheckedKeys(){
                this.$refs.menuTree.setCheckedKeys([])
            },

        }
    }
</script>

<style lang="scss" scoped>
    .menu-tree-select{
        border: 1px solid #DCDFE6;
        padding: 5px;
        height: 230px;
        overflow: unset;
        overflow-y: auto;
        border-radius: 5px;
    }
</style>
